'use strict';

/**
 * Returns the expected number of express checkout buttons that may be rendered for the given component.
 * @param {Object} component - the SFPP component
 * @returns {integer} expected number of buttons
 */
function getExpectedExpressCheckoutButtonsCount(component) {
    var renderedTypes = component.getRenderedTypes();
    var count = 0;
    if (renderedTypes.indexOf('applepay') !== -1 || renderedTypes.indexOf('paymentrequest') !== -1) {
        count++;
    }
    if (renderedTypes.indexOf('paypalexpress') !== -1) {
        count++;
    }
    return count;
}

/**
 * Register payment request buttons on the page that have not yet been registered.
 */
function registerPaymentRequestButtons() {
    if (window.sfpp) {
        sfpp.ready(function () {   // eslint-disable-line
            $('.salesforce-paymentrequest-element').each(function () {
                var element = $(this);
                if (element.hasClass('salesforce-paymentrequest-element-registered')) {
                    // Only register each button once
                    return;
                }

                element.addClass('salesforce-paymentrequest-element-registered');

                var errorElement = $('.' + element.data('errorsclass'));
                var paymentrequest = sfpp.get(element.data('paymentrequestid'));   // eslint-disable-line

                var paypalMessage = element.closest('.checkout-buttons').find('.salesforce-paypal-messaging');

                /**
                 * Shows/hides PayPal messages component based on rendered payment method types.
                 */
                function updatePayPalMessageVisibility() {
                    paypalMessage.toggleClass('salesforce-paypal-messaging-hidden', paymentrequest.getRenderedTypes().indexOf('paypalexpress') === -1);
                }

                var expectedExpressCheckoutButtons = getExpectedExpressCheckoutButtonsCount(paymentrequest);
                var renderedExpressCheckoutButtons;

                /**
                 * Shows express checkout button skeletons.
                 */
                function showExpressCheckoutButtonSkeletons() {
                    element.addClass('salesforce-paymentrequest-element-loading');
                    element.prepend('<div class="salesforce-paymentrequest-element-skeletons"></div>');
                    for (var i = 0; i < expectedExpressCheckoutButtons; i++) {
                        element.children('.salesforce-paymentrequest-element-skeletons').append('<div class="salesforce-paymentrequest-element-skeleton"><button type="button" class="btn" disabled></button></div>');
                    }
                    renderedExpressCheckoutButtons = 0;
                }

                /**
                 * Removes express checkout button skeletons.
                 */
                function removeExpressCheckoutButtonSkeletons() {
                    element.children('.salesforce-paymentrequest-element-skeletons').remove();
                    element.removeClass('salesforce-paymentrequest-element-loading');
                }

                // Show PayPal messages component after initial render
                setTimeout(updatePayPalMessageVisibility);

                // Show express checkout buttons skeletons while initially loading
                showExpressCheckoutButtonSkeletons();

                paymentrequest.on('render', function () {
                    // Show express checkout buttons skeletons after refreshing
                    expectedExpressCheckoutButtons = getExpectedExpressCheckoutButtonsCount(paymentrequest);
                    showExpressCheckoutButtonSkeletons();

                    // Show/hide PayPal messages component after refreshing
                    updatePayPalMessageVisibility();
                });

                /**
                 * Called each time an express checkout button is shown.
                 */
                function expressCheckoutButtonShown() {
                    renderedExpressCheckoutButtons++;
                    if (renderedExpressCheckoutButtons >= expectedExpressCheckoutButtons) {
                        removeExpressCheckoutButtonSkeletons();
                    }
                }

                paymentrequest.on('paypal.init', expressCheckoutButtonShown);
                paymentrequest.on('paymentrequest.init', expressCheckoutButtonShown);

                // Update error message on change
                paymentrequest.on('change', function (event) {
                    if (event.error) {
                        // Inform the customer that there is an error.
                        errorElement.empty().text(event.error.message);
                    } else {
                        // Clear out error message
                        errorElement.empty();
                    }
                });

                $('body').on('cart:beforeUpdate cart:beforeShippingMethodSelected checkout:beforeShippingMethodSelected promotion:beforeUpdate checkout:beforeGiftCertificateUpdate', function () {
                    element.attr('disabled', true);
                });

                $('body').on('cart:update cart:shippingMethodSelected checkout:shippingMethodSelected promotion:success promotion:error checkout:giftCertificateUpdate', function (e, data) {
                    paymentrequest.updatePaymentRequest(data.paymentRequestOptions);
                    var enabled = data.paymentRequestOptions.total.amount > 0;
                    element.attr('disabled', !enabled);

                    paypalMessage.find('div[data-pp-message]').attr('data-pp-amount', data.paypalAmount);
                });

                $('body').on('checkout:billingCountrySelected', function (e, data) {
                    // Update payment billing details and refresh when country changed
                    paymentrequest.updateBillingDetails(data.billingDetails);
                    paymentrequest.refresh();
                });

                paymentrequest.on('payment', function () {
                    $.ajax({
                        url: element.data('placeorder'),
                        method: 'POST',
                        contentType: 'application/json; charset=UTF-8',
                        success: function (data) {
                            if (data.error) {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(data.errorMessage);
                            } else {
                                // Show the order confirmation page

                                var redirect = $('<form>')
                                    .appendTo(document.body)
                                    .attr({
                                        method: 'POST',
                                        action: data.continueUrl
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderID',
                                        value: data.orderID
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderToken',
                                        value: data.orderToken
                                    });

                                redirect.submit();
                            }
                        },
                        error: function (err) {
                            // Inform the customer that there is an error.
                            errorElement.empty().text(err.message);
                        }
                    });
                });

                paymentrequest.on('paypal.approve', function () {
                    $.ajax({
                        url: element.data('paypalapprove'),
                        type: 'post',
                        data: '',
                        success: function (data) {
                            window.location.href = data.redirectUrl;
                        },
                        error: function (err) {
                            if (err.responseJSON && err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            } else {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(err.responseJSON.message);
                            }
                        }
                    });
                });
            });
        });
    }
}

module.exports = function () {
    $('body').on('paymentrequestbutton:register', function () {
        registerPaymentRequestButtons();
    });
};
